import LuFinancialActivity from './LuFinancialActivity'
import RequirementLevel from './RequirementLevel'
// import CensusTotal from './CensusTotal'
// import Comments from './Comments'
// import DistrictTaxes from './DistrictTaxes'
// import FinancialReportHistory from './FinancialReportHistory'
// import FinancialReportRecord from './FinancialReportRecord'
export default class FinancialReport {
  constructor(objIn = {}) {
    const defaults = {
      //FinancialReportID is required
      FinancialReportID: 0,
      //FinancialActivityID is required
      FinancialActivityID: 0,
      //RequirementLevelID is required
      RequirementLevelID: 0,
      //ReportRequired is required
      ReportRequired: false,
      DateReceived: null,
      DateEntered: null,
      OnTime: null,
      ManagerApprove: null,
      ManagerUserName: null,
      Assistant_Print: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.FinancialReportID = obj.FinancialReportID
    this.FinancialActivityID = obj.FinancialActivityID
    this.RequirementLevelID = obj.RequirementLevelID
    this.ReportRequired = obj.ReportRequired
    this.DateReceived = obj.DateReceived
    this.DateEntered = obj.DateEntered
    this.OnTime = obj.OnTime
    this.ManagerApprove = obj.ManagerApprove
    this.ManagerUserName = obj.ManagerUserName
    this.Assistant_Print = obj.Assistant_Print

    this.LuFinancialActivity = new LuFinancialActivity(
      obj.LuFinancialActivity || {}
    )
    this.RequirementLevel = new RequirementLevel(obj.RequirementLevel || {})
    // this.CensusTotal = []
    // if (obj.CensusTotal) {
    //   obj.CensusTotal.forEach((item) => {
    //     this.CensusTotal.push(new CensusTotal(item))
    //   })
    // }
    // this.Comments = []
    // if (obj.Comments) {
    //   obj.Comments.forEach((item) => {
    //     this.Comments.push(new Comments(item))
    //   })
    // }
    // this.DistrictTaxes = []
    // if (obj.DistrictTaxes) {
    //   obj.DistrictTaxes.forEach((item) => {
    //     this.DistrictTaxes.push(new DistrictTaxes(item))
    //   })
    // }
    // this.FinancialReportHistory = []
    // if (obj.FinancialReportHistory) {
    //   obj.FinancialReportHistory.forEach((item) => {
    //     this.FinancialReportHistory.push(new FinancialReportHistory(item))
    //   })
    // }
    // this.FinancialReportRecord = []
    // if (obj.FinancialReportRecord) {
    //   obj.FinancialReportRecord.forEach((item) => {
    //     this.FinancialReportRecord.push(new FinancialReportRecord(item))
    //   })
    // }
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.LuFinancialActivity = null
    obj.RequirementLevel = obj.RequirementLevel.getSaveData()
    return obj
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.LuFinancialActivity = null
    obj.RequirementLevel = null
    // obj.CensusTotal = []
    // if (this.CensusTotal) {
    //   this.CensusTotal.forEach((item) => {
    //     obj.CensusTotal.push(item.removeRelated())
    //   })
    // }
    // obj.Comments = []
    // if (this.Comments) {
    //   this.Comments.forEach((item) => {
    //     obj.Comments.push(item.removeRelated())
    //   })
    // }
    // obj.DistrictTaxes = []
    // if (this.DistrictTaxes) {
    //   this.DistrictTaxes.forEach((item) => {
    //     obj.DistrictTaxes.push(item.removeRelated())
    //   })
    // }
    // obj.FinancialReportHistory = []
    // if (this.FinancialReportHistory) {
    //   this.FinancialReportHistory.forEach((item) => {
    //     obj.FinancialReportHistory.push(item.removeRelated())
    //   })
    // }
    // obj.FinancialReportRecord = []
    // if (this.FinancialReportRecord) {
    //   this.FinancialReportRecord.forEach((item) => {
    //     obj.FinancialReportRecord.push(item.removeRelated())
    //   })
    // }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.LuFinancialActivity = null
    obj.RequirementLevel = null
    // obj.CensusTotal = null
    // obj.Comments = null
    // obj.DistrictTaxes = null
    // obj.FinancialReportHistory = null
    // obj.FinancialReportRecord = null
    return obj
  }
}
