<template>
  <v-row>
    <v-col sm="12">
      <v-card class="mx-auto">
        <v-list-item class="primary">
          <v-list-item-content>
            <v-list-item-title
              v-if="isNew"
              class="headline text-left white--text"
              >Add Entity</v-list-item-title
            >
            <v-list-item-title v-else class="headline text-left white--text"
              >Edit Entity</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-card-text>
          <v-row>
            <v-col class="py-0" sm="12" md="6">
              <v-text-field
                label="Full Name"
                v-model="entity.FullName"
                :rules="[(v) => !!v || 'Full name is required']"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" sm="12" md="6">
              <v-text-field
                label="Short Name"
                v-model="entity.ShortName"
                :rules="[(v) => !!v || 'Short name is required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" sm="12" md="6">
              <v-text-field
                label="Tax Number"
                v-model="entity.TaxNum"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" sm="12" md="6">
              <v-text-field
                label="Tax Number Description"
                v-model="entity.TaxNumDesc"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" sm="12" md="3">
              <v-text-field
                prefix="+1"
                label="Phone"
                v-mask="maskPhone"
                v-model="entity.Phone"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" sm="12" md="3">
              <v-text-field
                label="Extension"
                v-mask="maskExt"
                v-model="entity.Extension"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" sm="12" md="3">
              <v-text-field
                prefix="+1"
                label="Fax"
                v-mask="maskPhone"
                v-model="entity.Fax"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" sm="12" md="3">
              <v-text-field
                label="Website"
                v-model="entity.Website"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" sm="12" md="3">
              <v-text-field
                label="Mailing Address"
                v-model="entity.Address.Address1"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" sm="12" md="3">
              <v-text-field
                label="City"
                v-model="entity.Address.City"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" sm="12" md="3">
              <v-select
                :items="stateName"
                item-text="Name"
                item-value="ID"
                label="State"
                v-model="entity.Address.StateId"
                clearable
              ></v-select>
            </v-col>
            <v-col class="py-0" sm="12" md="3">
              <v-text-field
                label="Zip"
                v-model="entity.Address.Zip"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" sm="12" md="3">
              <v-text-field
                label="Physical Address"
                v-model="entity.PAddress.Address1"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" sm="12" md="3">
              <v-text-field
                label="City"
                v-model="entity.PAddress.City"
              ></v-text-field>
            </v-col>
            <v-col class="py-0" sm="12" md="3">
              <v-select
                :items="stateName"
                item-text="Name"
                item-value="ID"
                label="State"
                v-model="entity.PAddress.StateId"
                clearable
              ></v-select>
            </v-col>
            <v-col class="py-0" sm="12" md="3">
              <v-text-field
                label="Zip"
                v-model="entity.PAddress.Zip"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" sm="2">
              <v-checkbox
                v-model="entity.IsActive"
                label="Is Active?"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- SAVE BTN -->
    </v-col>
  </v-row>
</template>

<!-- SCRIPTS -->
<script>
import {mask} from 'vue-the-mask'
import {authMethods, authComputed} from '@state/helpers'
import {get, sync, commit, call} from 'vuex-pathify'
import {debounce, range} from 'lodash'
import Rules from '@validation/rules'

export default {
  directives: {
    mask,
  },
  model: {
    prop: 'entity',
    event: 'change',
  },
  props: {
    entity: Object,
  },
  data: () => ({
    // selectedAuditEntity: {},
    // selectedEntityAddress: {},
    rules: Rules,
    maskPhone: '###-###-####',
    maskExt: '####',
    maskZip: '#####',

    lazy: false,
    UserRoles: '',
    auditEntity: '',
    FullName: '',
  }),
  created() {
    this.loadStates()
  },
  computed: {
    ...authComputed,
    stateName: get('luState/luState'),
    isNew() {
      return !this.entity.ID > 0
    },
  },
  methods: {
    loadStates: call('luState/loadLuState'),
    // saveAuditEntity: call('auditEntity/saveAuditEntity'),
    // createAuditEntity: call('auditEntity/createAuditEntity'),
    // updateAuditEntity: call('auditEntity/updateAuditEntity'),
    // async validate() {
    //   this.$refs.entityForm.validate()
    // },
    reset() {
      this.$refs.form.reset()
    },
    isActive() {
      return true
    },

    // saveEntity() {
    //   this.Loading = true
    //   if (this.entity.ID > 0) {
    //     this.updateEntityDetails()
    //   } else {
    //     this.createEntityDetails()
    //   }
    // },

    // createEntityDetails() {
    //   this.validate().then(() => {
    //     if (this.valid) {
    //       this.saveAuditEntity(this.entity.getSaveData()).then(
    //         (res) => {
    //           this.$router.push({ name: 'entity', params: { id: res.data.ID } })
    //         },
    //         (err) => {}
    //       )
    //     }
    //   })
    // },

    // updateEntityDetails() {
    //   this.validate().then(() => {
    //     if (this.valid) {
    //       this.updateAuditEntity(this.entity).then(
    //         (res) => {
    //           // this.$router.push({ name: 'entity', params: { id: res.data.ID } })
    //         },
    //         (err) => {}
    //       )
    //     }
    //   })
    // },
  },
}
</script>

<!-- STYLES -->
<style scoped lang="scss">
//@import '@design';
// .col {
//   padding-top: 0;
//   padding-bottom: 0;
// }
</style>