import { render, staticRenderFns } from "./publicFundsDetails.vue?vue&type=template&id=4d99f350&scoped=true&"
import script from "./publicFundsDetails.vue?vue&type=script&lang=js&"
export * from "./publicFundsDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d99f350",
  null
  
)

export default component.exports