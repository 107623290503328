<template>
  <div>
    <v-row>
      <v-col sm="12">
        <v-card>
          <v-expansion-panels v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                class="primary headline text-left white--text"
              >
                Financial Reports
                <template v-slot:actions>
                  <v-icon class="white--text">$vuetify.icons.expand</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-row justify="end">
                    <v-col>
                      <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col sm="auto" class="mt-4">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            color="primary"
                            @click="openReportDialog()"
                            v-on="on"
                            fab
                            depressed
                            small
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Add Financial Report</span>
                      </v-tooltip>
                    </v-col>-->
                    <v-col sm="auto" class="mt-4">
                      <excelExport
                        :summaryData="summaryData"
                        :data="mappedData"
                        :exportFields="excelFields"
                        worksheet="Financial Reports"
                        :name="`Financial_Reports_${entity.ShortName}.xlsx`"
                        >Export</excelExport
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
                <template>
                  <v-data-table
                    item-key="ID"
                    :headers="visibleHeaders"
                    :items="mappedData"
                    :items-per-page="-1"
                    :search="search"
                    :sort-by.sync="pagination.sortBy"
                    :sort-desc.sync="pagination.descending"
                    class="pt-4"
                    dense
                  >
                    <template v-slot:item.ReportRequired="{item}">
                      {{ item.ReportRequired ? 'Yes' : 'No' }}
                    </template>
                    <template v-slot:item.OnTime="{item}">
                      {{ item.OnTime ? 'Yes' : 'No' }}
                    </template>
                    <template
                      v-slot:group.header.data-table-expand="{on, props}"
                    >
                      <span>Expand</span>
                    </template>
                    <template v-slot:item.reportEdit="{item}">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            icon
                            @click="openEditDialog(item)"
                          >
                            <v-icon class="clickable" color="primary"
                              >mdi-pencil</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Edit Report Information</span>
                      </v-tooltip>
                    </template>
                    <template
                      v-if="isSupervisor || isSuperUser"
                      v-slot:item.financialReportDelete="{item}"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-btn
                            small
                            color="primary"
                            v-on="on"
                            @click="openDeletefinancialReport(item)"
                            icon
                          >
                            <v-icon class="clickable" color="primary"
                              >mdi-delete</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>Delete Report</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent v-model="dialog" scrollable width="850">
      <v-form
        ref="financialReportForm"
        @submit.prevent
        lazy-validation
        v-model="valid"
      >
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text">Financial Report</h3>
          </v-card-title>
          <v-card-text class="pa-4">
            <v-row>
              <v-col sm="12" md="6">
                <!-- Financial Activity -->
                <v-select
                  v-model="selectedFinancialReport.FinancialActivityID"
                  item-text="FinancialActivityName"
                  item-value="FinancialActivityID"
                  :rules="[rules.fieldRequired]"
                  :items="availableFinancialActivities"
                  label="Financial Activity"
                  >--></v-select
                >
              </v-col>
              <v-col sm="12" md="6">
                <v-autocomplete
                  v-if="
                    selectedFinancialActivity &&
                    selectedFinancialActivity.cpaActivity
                  "
                  v-model="selectedFinancialReport.RequirementLevel.CPAEntityID"
                  item-text="FullName"
                  item-value="ID"
                  :items="cpaEntities"
                  label="CPA Entity"
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="6">
                <BaseDatePickerWithText
                  label="Date Received mm/dd/yyyy"
                  v-model="selectedFinancialReport.DateReceived"
                ></BaseDatePickerWithText>
              </v-col>
              <v-col sm="12" md="3">
                <v-checkbox
                  v-model="selectedFinancialReport.ReportRequired"
                  label="Report Required"
                ></v-checkbox>
              </v-col>
              <v-col sm="12" md="3">
                <v-checkbox
                  v-model="selectedFinancialReport.OnTime"
                  label="On Time"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12">
                <v-combobox
                  v-model="selectedFinancialReport.RequirementLevel.ReportYear"
                  :rules="[rules.fieldRequired]"
                  :items="availableYears"
                  label="Report Year"
                ></v-combobox>
              </v-col>
              <v-col sm="12">
                <v-autocomplete
                  v-model="
                    selectedFinancialReport.RequirementLevel.IncludedWith
                      .AuditIncludedWith
                  "
                  :items="includeWithEntities"
                  item-text="FullName"
                  item-value="ID"
                  label="Included With"
                  clearable
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              :disabled="saving"
              @click="validateAndSaveFinancialReport"
              >Save</v-btn
            >
            <v-btn outlined color="primary" @click="cancelFinancialReport"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog
      persistent
      v-model="deleteFinancialReportDialog"
      scrollable
      width="850"
    >
      <v-form
        ref="financialReportForm"
        @submit.prevent
        lazy-validation
        v-model="valid"
      >
        <v-card class="elevation-3">
          <v-card-title class="primary">
            <h3 class="hpHeading white--text"
              >Are you sure you want to delete this Financial Report?</h3
            >
          </v-card-title>
          <v-card-text class="pa-4"> </v-card-text>
          <v-card-actions>
            <v-btn @click="closeDeleteFinancialReport" color="primary"
              >Cancel</v-btn
            >
            <v-btn
              @click="deleteSelectedFinancialReport"
              outlined
              color="primary"
              >Delete</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
// import ProjectReviews from '@classes/ProjectReviews'
import {debounce, range} from 'lodash'
import dayjs from 'dayjs'
import {get, sync, commit, call} from 'vuex-pathify'
import excelExport from '@components/excelExport'

import Rules from '../../validation/rules'
import FinancialReport from '@classes/FinancialReport'
import {authMethods, authComputed} from '@state/helpers'
export default {
  props: {
    entity: Object,
    entityID: Number,
  },
  components: {
    excelExport,
  },
  data() {
    return {
      search: '',
      panel: [0],
      data: [],
      pagination: {sortBy: 'DateReceived', descending: true},
      dialog: false,
      deleteFinancialReportDialog: false,
      valid: true,
      selectedFinancialReport: new FinancialReport(),
      rules: Rules,
      entities: [],
      saving: false,
    }
  },
  computed: {
    ...authComputed,
    luFinancialActivity: get('luFinancialActivity/luFinancialActivity'),
    headers() {
      return [
        {
          text: '',
          align: 'left',
          value: 'reportEdit',
          visible: true,
          sortable: false,
        },
        {
          text: 'Financial Report ID',
          align: 'left',
          value: 'FinancialReportID',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Financial Activity ID',
          align: 'left',
          value: 'FinancialActivityID',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Financial Activity Name',
          align: 'left',
          value: 'financialActivity',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Report Required',
          align: 'left',
          value: 'ReportRequired',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Date Received',
          align: 'left',
          value: 'DateReceived',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Date Entered',
          align: 'left',
          value: 'DateEntered',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'On Time',
          align: 'left',
          value: 'OnTime',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'CPA',
          align: 'left',
          value: 'cpaEntity.ShortName',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: 'Audit Included With',
          align: 'left',
          value: 'includedWithEntity.ShortName',
          sortable: 'true',
          visible: true,
          filterable: true,
        },
        {
          text: '',
          align: 'left',
          value: 'financialReportDelete',
          visible: this.isSupervisor || this.isSuperUser,
          sortable: false,
        },
      ]
    },
    visibleHeaders() {
      return this.headers.filter((h) => h.visible)
    },
    excelFields() {
      let fields = {}
      this.visibleHeaders.forEach((item) => {
        if (item.text) {
          fields[item.text] = item.value
        }
      })
      return fields
    },
    summaryData() {
      var currentUrl = window.location.href
      return [
        {
          Name: 'Entity',
          Data: `${this.entity.ID} - ${this.entity.FullName}`,
        },
        {Name: 'Link', Data: currentUrl},
        {Name: 'Title', Data: 'Financial Reports'},
      ]
    },
    mappedData() {
      var data = this.data.map((item) => {
        return {
          ...item,
          financialActivity: item.LuFinancialActivity.FinancialActivityName,
          includedWith: item.RequirementLevel.IncludedWith
            ? item.RequirementLevel.IncludedWith.AuditIncludedWith
            : '',
          includedWithEntity: item.RequirementLevel.IncludedWith
            ? this.entities.find(
                (e) =>
                  e.ID == item.RequirementLevel.IncludedWith.AuditIncludedWith
              )
            : '',
          cpaEntity: this.cpaEntities.find(
            (e) => e.ID == item.RequirementLevel.CPAEntityID
          ),
        }
      })
      return data
    },
    availableYears() {
      let yearList = _.range(
        dayjs().add(-6, 'years').year(),
        dayjs().add(6, 'years').year(),
        1
      )
      let currentYear = dayjs().year()
      if (
        this.selectedFinancialReport &&
        this.selectedFinancialReport.RequirementLevel &&
        this.selectedFinancialReport.RequirementLevel.ReportYear
      ) {
        if (
          !yearList.find(
            (y) => y == this.selectedFinancialReport.RequirementLevel.ReportYear
          )
        ) {
          yearList.push(
            this.selectedFinancialReport.RequirementLevel.ReportYear
          )
        }
      }
      yearList.splice(yearList.indexOf(currentYear), 1)
      yearList.unshift(currentYear)
      return yearList
    },
    availableFinancialActivities() {
      var financialActivities = this.luFinancialActivity.filter((f) => f.Active)
      // if existing record make sure the previous selection is available
      if (this.selectedFinancialReport.FinancialReportID > 0) {
        var selectedFinancialActivity = this.luFinancialActivity.find(
          (f) =>
            f.FinancialActivityID ==
            this.selectedFinancialReport.FinancialActivityID
        )
        if (
          !financialActivities.find(
            (f) =>
              f.FinancialActivityID ==
              this.selectedFinancialReport.FinancialActivityID
          ) &&
          selectedFinancialActivity
        ) {
          financialActivities.push(selectedFinancialActivity)
        }
      }
      // sort by sort order column
      //   financialActivities.sort((a, b) => {
      //     return a.SortOrder != b.SortOrder
      //       ? a.SortOrder - b.SortOrder
      //       : a.FinancialActivityName > b.FinancialActivityName
      //       ? 1
      //       : -1
      //   })
      return financialActivities
    },
    includeWithEntities() {
      return this.entities
    },
    cpaEntities() {
      //filter to just cpa entities
      return this.entities.filter(
        (item) => item.AuditEntityPublicFunds.EntityTypeID == 46
      )
    },
    selectedFinancialActivity() {
      if (this.selectedFinancialReport.FinancialActivityID) {
        return this.luFinancialActivity.find(
          (f) =>
            f.FinancialActivityID ==
            this.selectedFinancialReport.FinancialActivityID
        )
      }
    },
  },
  methods: {
    loadLuFinancialActivity: call(
      'luFinancialActivity/loadLuFinancialActivity'
    ),
    saveFinancialReport: call('financialReport/saveFinancialReport'),
    deleteFinancialReport: call('financialReport/deleteFinancialReport'),
    openEditDialog(value) {
      this.selectedFinancialReport = new FinancialReport(value)
      this.dialog = true
      if (this.$refs.financialReportForm) {
        this.$refs.financialReportForm.resetValidation()
      }
    },
    openReportDialog() {
      this.selectedFinancialReport = new FinancialReport()
      this.dialog = true
      if (this.$refs.financialReportForm) {
        this.$refs.financialReportForm.resetValidation()
      }
    },
    openDeletefinancialReport(item) {
      this.selectedFinancialReport = new FinancialReport(item)
      this.deleteFinancialReportDialog = true
    },
    closeDeleteFinancialReport() {
      this.deleteFinancialReportDialog = false
    },
    loadFinancialReports() {
      const url = `FinancialReport/ByEntity/${this.entityID}`
      this.$axios.get(url).then(
        (res) => {
          this.data = res.data
        },
        (err) => {
          console.error(err)
        }
      )
    },
    loadPFEntities() {
      const url = `AuditEntity/PublicFunds`
      this.$axios.get(url).then(
        (res) => {
          this.entities = res.data
        },
        (err) => {
          console.error(err)
        }
      )
    },
    validateAndSaveFinancialReport() {
      this.validate().then(() => {
        if (this.valid) {
          let financialReport = this.selectedFinancialReport.getSaveData()
          if (financialReport.FinancialReportID == 0) {
            financialReport.RequirementLevel.EntityID = this.entityID
          }
          console.devlog(financialReport)
          this.saving = true
          this.saveFinancialReport(financialReport).then(
            (res) => {
              this.loadFinancialReports()
              this.dialog = false
              this.$refs.financialReportForm.reset()
              this.saving = false
            },
            (err) => {
              this.saving = false
            }
          )
        }
      })
    },
    deleteSelectedFinancialReport() {
      this.deleteFinancialReport(this.selectedFinancialReport.root())
        .then((res) => {
          this.selectedFinancialReport = new FinancialReport()
          this.loadFinancialReports()
          this.deleteFinancialReportDialog = false
        })
        .catch((error) => {
          console.error(error)
        })
    },
    cancelFinancialReport() {
      this.dialog = false
    },
    async validate() {
      this.$refs.financialReportForm.validate()
    },
  },
  created() {
    this.loadPFEntities()
    this.loadFinancialReports()
    this.loadLuFinancialActivity()
  },
  beforeDestroy() {},
  watch: {},
}
</script>
<style scoped></style>
