<template>
  <div>
    <v-container fluid>
      <div>
        <v-breadcrumbs :items="breadcrumbs" divider=">" large></v-breadcrumbs>
      </div>
      <v-row>
        <!-- ADD/EDIT ENTITY -->
        <v-col sm="12">
          <v-form ref="entityForm" v-model="valid" lazy-validation>
            <entityDetails :entity="selectedAuditEntity"></entityDetails>
            <publicFundsDetails
              v-if="currentUser.DivisionID == 4"
              :publicFundsEntity="selectedAuditEntity.AuditEntityPublicFunds"
            ></publicFundsDetails>
          </v-form>
          <v-row
            class="pb-3"
            v-if="isSuperUser || isProjectCreator"
            justify="end"
          >
            <v-col sm="auto">
              <v-btn
                color="primary"
                :disabled="saving"
                @click="validateAndSave"
              >
                <v-icon left>mdi-content-save</v-icon>Save Entity
              </v-btn>
            </v-col>
          </v-row>
          <div class="stickyBanner">
            <v-row>
              <v-col sm="12" md="8">
                <v-row justify="start" no-gutters>
                  <v-col sm="auto" class="ml-2">
                    <div>
                      <span>{{ selectedAuditEntity.ID }} -&nbsp;</span>
                      <span>{{ selectedAuditEntity.FullName }}</span>
                    </div>
                  </v-col>
                  <!-- <v-col sm="auto" class="ml-2"></v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </div>
          <financialReports
            v-if="currentUser.DivisionID == 4 && selectedAuditEntity.ID > 0"
            :entity="selectedAuditEntity"
            :entityID="entityID"
          ></financialReports>
          <!-- ENTITY CONTACTS -->
          <entityContacts
            v-if="!isNew"
            :entityContacts="selectedAuditEntity.EntityContact"
            :entityId="selectedAuditEntity.ID"
            @refresh="refresh"
          ></entityContacts>
          <!-- SUB-ENTITY -->
          <subEntities
            v-if="!isNew"
            :entityId="selectedAuditEntity.ID"
          ></subEntities>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<!-- SCRIPTS -->
<script>
import {authMethods, authComputed} from '@state/helpers'
import {get, sync, commit, call} from 'vuex-pathify'
import {debounce, range} from 'lodash'
import AuditEntity from '@classes/AuditEntity'
import AuditEntityDivision from '@classes/AuditEntityDivision'

import entityDetails from '@components/entity/entityDetails'
import entityContacts from '@components/entity/entityContacts'
import publicFundsDetails from '@components/entity/publicFundsDetails'
import financialReports from '@components/entity/financialReports'
import subEntities from '@components/entity/subEntities'
// import AuditSubEntity from '@classes/AuditSubEntity'
// import AuditEntity from '@classes/AuditEntity'
export default {
  components: {
    entityDetails,
    entityContacts,
    subEntities,
    publicFundsDetails,
    financialReports,
  },
  page() {
    return {
      title: this.selectedAuditEntity
        ? this.selectedAuditEntity.ShortName
        : 'Entity',
    }
  },
  data: () => ({
    selectedAuditEntity: new AuditEntity(),
    valid: true,
    saving: false,
  }),
  created() {
    if (this.$route.params.id) {
      this.refresh()
    } else {
      this.selectedAuditEntity = new AuditEntity()
    }
  },
  computed: {
    ...authComputed,
    entityID() {
      return parseInt(this.$route.params.id)
    },
    isNew() {
      return !this.selectedAuditEntity.ID > 0
    },
    breadcrumbs() {
      return [
        {
          text: 'Search',
          disabled: false,
          to: '/entities',
        },
      ]
    },
  },
  methods: {
    ...call('auditEntity', [
      'loadSelectedAuditEntity',
      'saveAuditEntity',
      'refreshSelectedAuditEntity',
    ]),
    validateAndSave() {
      this.validate().then(() => {
        if (this.valid) {
          if (this.isNew) {
            this.selectedAuditEntity.AuditEntityDivision.push(
              new AuditEntityDivision({DivisionID: this.currentUser.DivisionID})
            )
          }
          let entity = this.selectedAuditEntity.getSaveData()
          if (this.currentUser.DivisionID != 4) {
            entity.AuditEntityPublicFunds = null
          }
          this.saving = true
          this.saveAuditEntity(entity).then(
            (res) => {
              if (entity.ID > 0) {
                this.refreshSelectedAuditEntity()
              }
              this.saving = false
              if (this.selectedAuditEntity.ID == 0 && res.data) {
                this.$router.push({
                  name: 'entity',
                  params: {
                    id: res.data.ID,
                  },
                })
              }
            },
            (err) => {
              this.saving = false
            }
          )
        }
      })
    },
    async validate() {
      this.$refs.entityForm.validate()
    },
    refresh() {
      this.loadSelectedAuditEntity(this.$route.params.id).then((res) => {
        this.selectedAuditEntity = new AuditEntity(res.data)
      })
    },
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
.disabled {
  color: grey;
  pointer-events: none;
}
.v-breadcrumbs {
  padding-bottom: 0;
}
.v-application ul,
.v-application ol {
  padding-left: 10px;
}
.stickyBanner {
  border: 1px solid #358345;
  background-color: var(--v-customBackground-base);
  position: sticky;
  top: 4rem;
  z-index: 2;
}
</style>