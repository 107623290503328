import AuditEntity from './AuditEntity'
// import AuditEntityPublicFunds from './AuditEntityPublicFunds'
import RequirementLevel from './RequirementLevel'
export default class IncludedWith {
  constructor(objIn = {}) {
    const defaults = {
      //RequirementLevelID is required
      RequirementLevelID: 0,
      AuditIncludedWith: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.RequirementLevelID = obj.RequirementLevelID
    this.AuditIncludedWith = obj.AuditIncludedWith

    this.AuditIncludedWith1 = new AuditEntity(obj.AuditIncludedWith1 || {})
    // this.AuditIncludedWithNavigation = new AuditEntityPublicFunds(
    //   obj.AuditIncludedWithNavigation || {}
    // )
    //do not load this direction (circular reference)
    // this.RequirementLevel = new RequirementLevel(obj.RequirementLevel || {})
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.AuditIncludedWith1 = null
    // obj.AuditIncludedWithNavigation = null
    obj.RequirementLevel = null
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.AuditIncludedWith1 = null
    // obj.AuditIncludedWithNavigation = null
    obj.RequirementLevel = null
    return obj
  }
}
