import FinancialReport from './FinancialReport'
export default class LuFinancialActivity {
  constructor(objIn = {}) {
    const defaults = {
      //FinancialActivityID is required
      FinancialActivityID: 0,
      //FinancialActivityName is required
      FinancialActivityName: '',
      //Active is required
      Active: false,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.FinancialActivityID = obj.FinancialActivityID
    this.FinancialActivityName = obj.FinancialActivityName
    this.Active = obj.Active

    this.FinancialReport = []
    if (obj.FinancialReport) {
      obj.FinancialReport.forEach((item) => {
        this.FinancialReport.push(new FinancialReport(item))
      })
    }
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.FinancialReport = []
    if (this.FinancialReport) {
      this.FinancialReport.forEach((item) => {
        obj.FinancialReport.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.FinancialReport = null
    return obj
  }
}
