import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCard,[_c(VExpansionPanels,{attrs:{"multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c(VExpansionPanel,[_c(VExpansionPanelHeader,{staticClass:"primary headline text-left white--text",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c(VIcon,{staticClass:"white--text"},[_vm._v("$vuetify.icons.expand")])]},proxy:true}])},[_vm._v(" Financial Reports ")]),_c(VExpansionPanelContent,[_c(VCardText,[_c(VRow,{attrs:{"justify":"end"}},[_c(VCol,[_c(VTextField,{attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VCol,{staticClass:"mt-4",attrs:{"sm":"auto"}},[_c('excelExport',{attrs:{"summaryData":_vm.summaryData,"data":_vm.mappedData,"exportFields":_vm.excelFields,"worksheet":"Financial Reports","name":("Financial_Reports_" + (_vm.entity.ShortName) + ".xlsx")}},[_vm._v("Export")])],1)],1)],1),[_c(VDataTable,{staticClass:"pt-4",attrs:{"item-key":"ID","headers":_vm.visibleHeaders,"items":_vm.mappedData,"items-per-page":-1,"search":_vm.search,"sort-by":_vm.pagination.sortBy,"sort-desc":_vm.pagination.descending,"dense":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.pagination, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.pagination, "descending", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.pagination, "descending", $event)}},scopedSlots:_vm._u([{key:"item.ReportRequired",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ReportRequired ? 'Yes' : 'No')+" ")]}},{key:"item.OnTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.OnTime ? 'Yes' : 'No')+" ")]}},{key:"group.header.data-table-expand",fn:function(ref){
var on = ref.on;
var props = ref.props;
return [_c('span',[_vm._v("Expand")])]}},{key:"item.reportEdit",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},on),[_c(VIcon,{staticClass:"clickable",attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Report Information")])])]}},(_vm.isSupervisor || _vm.isSuperUser)?{key:"item.financialReportDelete",fn:function(ref){
var item = ref.item;
return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({attrs:{"small":"","color":"primary","icon":""},on:{"click":function($event){return _vm.openDeletefinancialReport(item)}}},on),[_c(VIcon,{staticClass:"clickable",attrs:{"color":"primary"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Report")])])]}}:null],null,true)})]],2)],1)],1)],1)],1)],1),_c(VDialog,{attrs:{"persistent":"","scrollable":"","width":"850"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"financialReportForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,{staticClass:"elevation-3"},[_c(VCardTitle,{staticClass:"primary"},[_c('h3',{staticClass:"hpHeading white--text"},[_vm._v("Financial Report")])]),_c(VCardText,{staticClass:"pa-4"},[_c(VRow,[_c(VCol,{attrs:{"sm":"12","md":"6"}},[_c(VSelect,{attrs:{"item-text":"FinancialActivityName","item-value":"FinancialActivityID","rules":[_vm.rules.fieldRequired],"items":_vm.availableFinancialActivities,"label":"Financial Activity"},model:{value:(_vm.selectedFinancialReport.FinancialActivityID),callback:function ($$v) {_vm.$set(_vm.selectedFinancialReport, "FinancialActivityID", $$v)},expression:"selectedFinancialReport.FinancialActivityID"}},[_vm._v("-->")])],1),_c(VCol,{attrs:{"sm":"12","md":"6"}},[(
                  _vm.selectedFinancialActivity &&
                  _vm.selectedFinancialActivity.cpaActivity
                )?_c(VAutocomplete,{attrs:{"item-text":"FullName","item-value":"ID","items":_vm.cpaEntities,"label":"CPA Entity","clearable":""},model:{value:(_vm.selectedFinancialReport.RequirementLevel.CPAEntityID),callback:function ($$v) {_vm.$set(_vm.selectedFinancialReport.RequirementLevel, "CPAEntityID", $$v)},expression:"selectedFinancialReport.RequirementLevel.CPAEntityID"}}):_vm._e()],1)],1),_c(VRow,[_c(VCol,{attrs:{"sm":"12","md":"6"}},[_c('BaseDatePickerWithText',{attrs:{"label":"Date Received mm/dd/yyyy"},model:{value:(_vm.selectedFinancialReport.DateReceived),callback:function ($$v) {_vm.$set(_vm.selectedFinancialReport, "DateReceived", $$v)},expression:"selectedFinancialReport.DateReceived"}})],1),_c(VCol,{attrs:{"sm":"12","md":"3"}},[_c(VCheckbox,{attrs:{"label":"Report Required"},model:{value:(_vm.selectedFinancialReport.ReportRequired),callback:function ($$v) {_vm.$set(_vm.selectedFinancialReport, "ReportRequired", $$v)},expression:"selectedFinancialReport.ReportRequired"}})],1),_c(VCol,{attrs:{"sm":"12","md":"3"}},[_c(VCheckbox,{attrs:{"label":"On Time"},model:{value:(_vm.selectedFinancialReport.OnTime),callback:function ($$v) {_vm.$set(_vm.selectedFinancialReport, "OnTime", $$v)},expression:"selectedFinancialReport.OnTime"}})],1)],1),_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VCombobox,{attrs:{"rules":[_vm.rules.fieldRequired],"items":_vm.availableYears,"label":"Report Year"},model:{value:(_vm.selectedFinancialReport.RequirementLevel.ReportYear),callback:function ($$v) {_vm.$set(_vm.selectedFinancialReport.RequirementLevel, "ReportYear", $$v)},expression:"selectedFinancialReport.RequirementLevel.ReportYear"}})],1),_c(VCol,{attrs:{"sm":"12"}},[_c(VAutocomplete,{attrs:{"items":_vm.includeWithEntities,"item-text":"FullName","item-value":"ID","label":"Included With","clearable":""},model:{value:(
                  _vm.selectedFinancialReport.RequirementLevel.IncludedWith
                    .AuditIncludedWith
                ),callback:function ($$v) {_vm.$set(_vm.selectedFinancialReport.RequirementLevel.IncludedWith
                    , "AuditIncludedWith", $$v)},expression:"\n                  selectedFinancialReport.RequirementLevel.IncludedWith\n                    .AuditIncludedWith\n                "}})],1)],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.saving},on:{"click":_vm.validateAndSaveFinancialReport}},[_vm._v("Save")]),_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.cancelFinancialReport}},[_vm._v("Cancel")])],1)],1)],1)],1),_c(VDialog,{attrs:{"persistent":"","scrollable":"","width":"850"},model:{value:(_vm.deleteFinancialReportDialog),callback:function ($$v) {_vm.deleteFinancialReportDialog=$$v},expression:"deleteFinancialReportDialog"}},[_c(VForm,{ref:"financialReportForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,{staticClass:"elevation-3"},[_c(VCardTitle,{staticClass:"primary"},[_c('h3',{staticClass:"hpHeading white--text"},[_vm._v("Are you sure you want to delete this Financial Report?")])]),_c(VCardText,{staticClass:"pa-4"}),_c(VCardActions,[_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.closeDeleteFinancialReport}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.deleteSelectedFinancialReport}},[_vm._v("Delete")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }