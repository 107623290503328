import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VContainer,{attrs:{"fluid":""}},[_c('div',[_c(VBreadcrumbs,{attrs:{"items":_vm.breadcrumbs,"divider":">","large":""}})],1),_c(VRow,[_c(VCol,{attrs:{"sm":"12"}},[_c(VForm,{ref:"entityForm",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('entityDetails',{attrs:{"entity":_vm.selectedAuditEntity}}),(_vm.currentUser.DivisionID == 4)?_c('publicFundsDetails',{attrs:{"publicFundsEntity":_vm.selectedAuditEntity.AuditEntityPublicFunds}}):_vm._e()],1),(_vm.isSuperUser || _vm.isProjectCreator)?_c(VRow,{staticClass:"pb-3",attrs:{"justify":"end"}},[_c(VCol,{attrs:{"sm":"auto"}},[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.saving},on:{"click":_vm.validateAndSave}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v("Save Entity ")],1)],1)],1):_vm._e(),_c('div',{staticClass:"stickyBanner"},[_c(VRow,[_c(VCol,{attrs:{"sm":"12","md":"8"}},[_c(VRow,{attrs:{"justify":"start","no-gutters":""}},[_c(VCol,{staticClass:"ml-2",attrs:{"sm":"auto"}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.selectedAuditEntity.ID)+" - ")]),_c('span',[_vm._v(_vm._s(_vm.selectedAuditEntity.FullName))])])])],1)],1)],1)],1),(_vm.currentUser.DivisionID == 4 && _vm.selectedAuditEntity.ID > 0)?_c('financialReports',{attrs:{"entity":_vm.selectedAuditEntity,"entityID":_vm.entityID}}):_vm._e(),(!_vm.isNew)?_c('entityContacts',{attrs:{"entityContacts":_vm.selectedAuditEntity.EntityContact,"entityId":_vm.selectedAuditEntity.ID},on:{"refresh":_vm.refresh}}):_vm._e(),(!_vm.isNew)?_c('subEntities',{attrs:{"entityId":_vm.selectedAuditEntity.ID}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }