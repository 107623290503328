import AuditEntity from './AuditEntity'
import IncludedWith from './IncludedWith'
import FinancialReport from './FinancialReport'
export default class RequirementLevel {
  constructor(objIn = {}) {
    const defaults = {
      //RequirementLevelID is required
      RequirementLevelID: 0,
      //EntityID is required
      EntityID: 0,
      //ReportYear is required
      ReportYear: null,
      ReportingYear: null,
      Level: null,
      CPAEntityID: null,
    }
    let obj = {}
    Object.assign(obj, defaults, objIn)
    this.RequirementLevelID = obj.RequirementLevelID
    this.EntityID = obj.EntityID
    this.ReportYear = obj.ReportYear
    this.ReportingYear = obj.ReportingYear
    this.Level = obj.Level
    this.CPAEntityID = obj.CPAEntityID

    this.Entity = new AuditEntity(obj.Entity || {})
    this.IncludedWith = new IncludedWith(obj.IncludedWith || {})
    this.FinancialReport = []
    if (obj.FinancialReport) {
      obj.FinancialReport.forEach((item) => {
        this.FinancialReport.push(new FinancialReport(item))
      })
    }
  }
  getSaveData() {
    let obj = Object.assign({}, this)
    obj.ReportYear = parseInt(obj.ReportYear)
    obj.ReportingYear = parseInt(obj.ReportYear)
    if (obj.IncludedWith.AuditIncludedWith) {
      obj.IncludedWith = obj.IncludedWith.removeRelated()
    } else {
      obj.IncludedWith = null
    }
    obj.Entity = null
    obj.FinancialReport = null
    return obj
  }
  removeRelated() {
    let obj = Object.assign({}, this)
    obj.Entity = null
    obj.IncludedWith = null
    obj.FinancialReport = []
    if (this.FinancialReport) {
      this.FinancialReport.forEach((item) => {
        obj.FinancialReport.push(item.removeRelated())
      })
    }
    return obj
  }
  root() {
    let obj = Object.assign({}, this)
    obj.Entity = null
    obj.IncludedWith = null
    obj.FinancialReport = null
    return obj
  }
}
