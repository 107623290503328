<template>
  <v-row>
    <v-col sm="12">
      <v-card>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header
              class="primary headline text-left white--text"
            >
              Sub-Entities
              <template v-slot:actions>
                <v-icon class="white--text">$vuetify.icons.expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row justify="end">
                  <v-col sm="auto">
                    <v-text-field
                      v-model="searchText"
                      prepend-inner-icon="mdi-text-box-search"
                      label="Search"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    v-if="isSuperUser || isProjectCreator"
                    sm="auto"
                    class="mt-4"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{on}">
                        <v-btn
                          color="primary"
                          v-on="on"
                          fab
                          depressed
                          small
                          @click="addSubEntity()"
                        >
                          <v-icon dark>mdi-account-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Add Sub-Entity</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-text>
              <template>
                <v-data-table
                  :headers="headers"
                  :items="auditSubEntities"
                  :server-items-length="total"
                  :loading="loading"
                  :sort-by.sync="pagination.sortBy"
                  :sort-desc.sync="pagination.descending"
                  :page.sync="pagination.page"
                  :items-per-page.sync="pagination.rowsPerPage"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10, 25, 50],
                  }"
                  class="pt-4"
                  dense
                >
                  <template v-slot:item.subEntityEdit="{item}">
                    <v-tooltip bottom>
                      <template v-slot:activator="{on}">
                        <v-btn
                          small
                          color="primary"
                          v-on="on"
                          icon
                          @click="editSubEntity(item)"
                        >
                          <v-icon class="clickable" color="primary"
                            >mdi-pencil</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit Sub-Entity</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.Description="{item}">{{
                    item.Description
                  }}</template>
                  <template v-slot:item.IsActive="{item}">{{
                    item.IsActive
                  }}</template>
                </v-data-table>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>

<!-- SCRIPTS -->
<script>
import {get, sync, commit, call} from 'vuex-pathify'
import {authMethods, authComputed} from '@state/helpers'
import {debounce, range} from 'lodash'
export default {
  model: {
    prop: 'subEntities',
    event: 'change',
  },
  props: {
    entityId: Number,
  },
  data: () => ({
    panel: 0,
    search: '',
    searchText: '',
    auditSubEntities: [],
    headers: [
      {
        text: 'Sub-Entity',
        align: 'center',
        value: 'subEntityEdit',
        sortable: false,
      },
      {
        text: 'Description',
        align: 'center',
        sortable: 'true',
        value: 'Description',
      },
      {
        text: 'Active?',
        align: 'center',
        sortable: 'true',
        value: 'IsActive',
      },
    ],
    total: 0,
    searchCount: 0,
    pagination: {
      page: 1,
      sortBy: 'Description',
      descending: false,
      rowsPerPage: 25,
    },
  }),
  created() {
    this.searchSubEntities()
    // if (this.$route.params.EntityID) {
    //   //get sub entity
    //   this.loadSelectedAuditSubEntity(this.$route.params.EntityID).then(
    //     (res) => {
    //       this.selectedAuditSubEntity = res.data
    //     }
    //   )
    // } else {
    //   //create new sub entity
    //   !this.selectedAuditSubEntity.EntityID > 0
    // }
  },
  watch: {
    entityId(nval, oval) {
      if (!!nval) {
        this.bounce(this)
      }
    },
    pagination: {
      handler() {
        this.bounce(this)
      },
      deep: true,
    },
    searchText: {
      handler() {
        this.bounce(this)
      },
    },
  },

  methods: {
    handleError: call('errors/handleError'),
    loadSelectedAuditSubEntity: call(
      'auditSubEntity/loadSelectedAuditSubEntity'
    ),
    searchSubEntities() {
      if (this.entityId) {
        if (!this.pagination.sortBy) {
          this.pagination.sortBy = 'Description'
          this.pagination.descending = false
        }
        if (!this.searchText) {
          this.searchText = ''
        }
        this.searchCount++
        const url = `AuditSubEntitiesSearch?pageNum=${
          this.pagination.page - 1
        }&pageSize=${this.pagination.rowsPerPage}&sortProperty=${
          this.pagination.sortBy
        }&sortDirection=${
          this.pagination.descending ? 'desc' : 'asc'
        }&searchText=${this.searchText}&entityId=${this.entityId}`
        this.$axios.get(url).then(
          (res) => {
            this.pagination.rowsPerPage = res.data.Page.Size
            this.pagination.page = res.data.Page.Number + 1
            this.total = res.data.Page.TotalElements
            this.auditSubEntities = res.data.AuditSubEntities
            this.searchCount--
          },
          (error) => {
            this.error = error
            this.handleError(error)
            console.error(error)
            this.searchCount--
          }
        )
      }
    },
    bounce: _.debounce((self) => {
      self.searchSubEntities()
    }, 750),

    editSubEntity(item) {
      this.$router.push({
        name: 'subEntity',
        params: {subEntityId: item.ID, entityId: this.entityId},
      })
    },
    addSubEntity() {
      this.$router.push({
        name: 'subEntity',
        params: {entityId: this.entityId},
      })
    },
  },
  computed: {
    ...authComputed,
    loading() {
      return this.searchCount > 0
    },
  },
}
</script>

<!-- STYLES -->
<style lang="scss">
//@import '@design';
</style>