<template>
  <v-row>
    <v-col xs="12">
      <v-card class="mx-auto">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header
              class="primary headline text-left white--text"
            >
              Public Funds Details
              <template v-slot:actions>
                <v-icon class="white--text">$vuetify.icons.expand</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card-text>
                <v-row>
                  <v-col class="py-0" sm="12" md="4">
                    <BaseDatePickerWithText
                      label="Date Entered mm/dd/yyyy"
                      v-model="publicFundsEntity.DateEntered"
                    ></BaseDatePickerWithText>
                  </v-col>
                  <v-col class="py-0" sm="12" md="4">
                    <BaseDatePickerWithText
                      label="Origination Date mm/dd/yyyy"
                      v-model="publicFundsEntity.OriginationDate"
                    ></BaseDatePickerWithText>
                  </v-col>
                  <v-col class="py-0" sm="12" md="4">
                    <BaseDatePickerWithText
                      label="Dissolution Date mm/dd/yyyy"
                      v-model="publicFundsEntity.DissolvedDate"
                    ></BaseDatePickerWithText>
                  </v-col>
                  <v-col class="py-0" sm="12" md="4">
                    <v-select
                      v-model="publicFundsEntity.EntityTypeID"
                      :items="entityTypeSort"
                      item-text="Description"
                      item-value="ID"
                      label="Entity Type"
                      :rules="[rules.fieldRequired]"
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col class="py-0" sm="12" md="4">
                    <v-select
                      v-model="publicFundsEntity.FiscalMonthYearEnd"
                      :items="months"
                      item-value="MonthIndex"
                      item-text="MonthName"
                      label="Fiscal Month Year End"
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col class="py-0" sm="12" md="4">
                    <v-select
                      v-model="publicFundsEntity.CountyID"
                      :items="luCounty"
                      item-text="Name"
                      item-value="ID"
                      label="County"
                      :rules="[rules.fieldRequired]"
                      clearable
                    ></v-select>
                  </v-col>
                  <v-col class="py-0" sm="12" md="4">
                    <v-text-field
                      v-model="publicFundsEntity.CensusNumber"
                      label="Census Number"
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0" sm="12" md="4">
                    <v-text-field
                      v-model="selectedCounty.Population"
                      label="County Population"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col class="py-0" sm="12" md="4">
                    <v-select
                      v-model="publicFundsEntity.CensusReportID"
                      :items="luCensusReport"
                      item-text="Name"
                      item-value="ID"
                      label="Census Report"
                      clearable
                    >
                      <template v-slot:item="{item, index}"
                        >{{ item.Name }} - {{ item.Description }}</template
                      >
                      <template v-slot:selection="{item, index}"
                        >{{ item.Name }} - {{ item.Description }}</template
                      >
                    </v-select>
                  </v-col>
                </v-row>
                <!-- <v-row>
            <v-col class="py-0" sm="12">
              <v-text-field v-model="auditSubEntity.Address.Address1" label="Address1"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" sm="12" md="4">
              <v-text-field v-model="auditSubEntity.Address.City" label="City"></v-text-field>
            </v-col>
            <v-col class="py-0" sm="12" md="4">
              <v-select
                v-model="auditSubEntity.Address.StateId"
                :items="luState"
                item-text="Abbreviation"
                item-value="ID"
                label="State"
                menu-props="auto, overflowY"
                clearable
              ></v-select>
            </v-col>
            <v-col class="py-0" sm="12" md="4">
              <v-text-field v-model="auditSubEntity.Address.Zip" label="Zip" v-mask="maskZip"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" sm="12" md="2">
              <v-checkbox v-model="auditSubEntity.IsActive" label="Is Active?"></v-checkbox>
            </v-col>
                </v-row>-->
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import {get, sync, commit, call} from 'vuex-pathify'

import dayjs from 'dayjs'
import localeData from 'dayjs/plugin/localeData'
dayjs.extend(localeData)
import Rules from '@validation/rules'
export default {
  components: {},
  created() {
    this.loadLuPFEntityType()
    this.loadLuCensusReport()
    this.loadLuCounty()
  },
  model: {
    prop: 'publicFundsEntity',
    event: 'change',
  },
  props: {
    publicFundsEntity: Object,
  },
  data() {
    return {
      panel: 0,
      rules: Rules,
    }
  },
  computed: {
    luPFEntityType: get('luPfEntityType/luPFEntityType'),
    luCensusReport: get('luCensusReport/luCensusReport'),
    luCounty: get('luCounty/luCounty'),
    months() {
      return dayjs.months().map((v, i) => {
        return {MonthName: v, MonthIndex: i + 1}
      })
    },
    selectedCounty() {
      if (this.publicFundsEntity.CountyID) {
        return this.luCounty.find(
          (x) => x.ID == this.publicFundsEntity.CountyID
        )
      } else {
        return {Population: ''}
      }
    },
    entityTypeSort() {
      // Getting the IsActive
      var list = this.luPFEntityType.filter((i) => i.IsActive)
      // if the record exists, make sure the previous selection is available
      if (this.publicFundsEntity.EntityTypeID > 0) {
        var selectedItem = this.luPFEntityType.find(
          (i) => i.ID == this.publicFundsEntity.EntityTypeID
        )
        if (
          selectedItem &&
          !list.find((c) => c.ID == this.publicFundsEntity.EntityTypeID)
        ) {
          list.push(selectedItem)
        }
      }
      // sort by sort order
      list.sort((a, b) => {
        let textField = 'DateType'
        let sort =
          !!b.SortOrder && !!a.SortOrder
            ? a.SortOrder != b.SortOrder
              ? a.SortOrder - b.SortOrder
              : a[textField] > b[textField]
              ? 1
              : -1
            : a.SortOrder != b.SortOrder
            ? !!a.SortOrder && !b.SortOrder
              ? -1
              : 1
            : a[textField] > b[textField]
            ? 1
            : -1
        return sort
      })
      return list
    },
  },
  methods: {
    loadLuPFEntityType: call('luPfEntityType/loadLuPFEntityType'),
    loadLuCensusReport: call('luCensusReport/loadLuCensusReport'),
    loadLuCounty: call('luCounty/loadLuCounty'),
  },
  watch: {},
}
</script>
<style scoped></style>
